@import "src/styles/mixins.scss";

.rating {
  @include col;

  align-items: center;
  gap: 5rem;

  height: 100%;
  padding: 0 34.5rem;
  padding-top: 14rem;

  background: url(~/src/assets/images/rating.webp) 0% 0% / cover no-repeat;
  background-position: center;

  &__header {
    @include col;

    align-items: center;
    justify-content: space-between;

    height: 8.875rem;
  }

  &__body {
    @include col;

    gap: 1.785rem;

    height: 38rem;
    padding: 0 1.05rem;
    width: 100%;

    overflow-y: scroll;
  }

  &__title {
    &--primary {
      color: #3d495a;
      font-size: 3.435rem;
      line-height: 3.435rem;
    }

    &--secondary {
      color: #ffffff;
      font-size: 5rem;
      line-height: 5rem;
    }
  }

  &__racer {
    @include row;

    gap: 2.145rem;
    justify-content: space-between;

    padding: 1.15rem 1.85rem;

    border-radius: 0.7865rem;
  }

  &__info {
    color: #cbddf8;
    font-size: 2.715rem;
    line-height: 2.715rem;
  }
}
