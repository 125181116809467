@import url("./styles/fonts.scss");

* {
  box-sizing: border-box;
  font-family: Giorgio Sans LCG;
  font-size: calc(100vw / 1920 * 14);
  font-weight: 700;
  user-select: none;
}

#root {
  height: 100vh;
}

body {
  overflow-x: hidden;

  background-color: #000000;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #161616;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(#3e4040, #373737);
  border: 1px solid #4f4f4f;
  border-radius: 10px;
}
