@import "src/styles/mixins.scss";

.game {
  @include row;

  &__info {
    @include row;

    justify-content: space-between;

    width: 100%;

    color: #3d495a;

    span {
      font-size: 2.857rem;
      line-height: 2.857rem;
    }

    &:hover {
      color: #ffffff;
    }

    &--selected,
    &--pending {
      color: #ffffff !important;
    }

    &--started {
      color: #3bf3ff;
    }

    &--cancelled {
      color: #70094a;
    }
  }

  &__result {
    inset: 0;
    position: absolute;

    height: 28.929rem;
    margin: auto;
    padding: 1.714rem;
    width: 25.214rem;

    background: #130f17;
    border-radius: 0.857rem;
    outline: none;

    &__close {
      top: 0;
      position: absolute;
      right: 0;

      padding: 0;

      background: none;
      border: none;
      outline: none;
    }

    &__content {
      @include col;

      gap: 0.571rem;
    }

    &__header {
      @include row;

      align-items: baseline;
      padding: 0 0.714rem;

      span:nth-of-type(1) {
        flex: 0.6;

        color: #ffffff;
        font-size: 4.571rem;
        line-height: 4.571rem;
      }
      span:nth-of-type(2),
      span:nth-of-type(3) {
        flex: 0.2;

        color: #717171;
        font-size: 1.429rem;
        line-height: 1.429rem;
        text-align: center;
      }
    }

    &__position {
      @include row;

      justify-content: space-between;

      padding: 0.714rem;
      width: 100%;

      background: #002527;
      border-radius: 0.286rem;
      color: #f2f2f2;
      font-size: 1.429rem;
      line-height: 1.429rem;

      &--first {
        background: #016268;
      }

      &--second {
        background: #02474b;
      }

      &--third {
        background: #002e31;
      }

      span:nth-of-type(1) {
        flex: 0.6;
      }
      span:nth-of-type(2),
      span:nth-of-type(3) {
        flex: 0.2;

        text-align: center;
      }
    }
  }
}
