@import "src/styles/mixins.scss";

.contacts {
  @include row;

  gap: 10.65rem;

  height: 100%;
  padding: 13.5rem 0 7.2rem 0;

  background: url(~/src/assets/images/contacts.webp) 0% 0% / cover no-repeat;
  background-position: center;

  > div:nth-of-type(1) {
    @include col;

    flex: 0 0 40.5rem;
    gap: 1rem;

    padding: 6rem 8rem;

    background: url(~/src/assets/images/info-background.webp) 0% 0% / cover no-repeat;
    background-position: center;
    clip-path: polygon(85% 0, 100% 15%, 100% 100%, 0 100%, 0 0);

    > div {
      @include col;

      gap: 1rem;
    }
  }

  > div:nth-of-type(2) {
    flex: 0 0 73.8rem;
  }

  &__title {
    color: #3d495a;
    font-size: 2rem;
    line-height: 2rem;
  }

  &__description {
    color: #000000;
    font-size: 2.571rem;
    line-height: 2rem;
  }
}
