@import "src/styles/mixins.scss";

.main {
  height: 100%;

  background: url(~/src/assets/images/main-ru.png) 0% 0% / cover no-repeat;
  background-position: center;

  &--am {
    background: url(~/src/assets/images/main-am.png) 0% 0% / cover no-repeat;
    background-position: center;
  }

  &--us {
    background: url(~/src/assets/images/main-us.png) 0% 0% / cover no-repeat;
    background-position: center;
  }

  &__play {
    left: 74vw;
    position: absolute;
    top: 50vh;

    height: 21.5vh;
    width: 12vw;

    background:
      url(~/src/assets/images/play.png) 0% 0% / 50% 50% no-repeat,
      linear-gradient(
        215deg,
        #40e7e7 3.5%,
        rgba(62, 70, 255, 0.6) 15%,
        rgba(255, 62, 220, 0.6) 25%,
        #ec42e5 35%,
        #703eff 100%
      ),
      linear-gradient(50deg, #1472fe 12%, #1472fe 70%);
    background-position: 60% 50%;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    outline: none;
    transition: filter 0.2s linear;

    &:hover {
      filter: brightness(1.5);
    }
    &:active {
      filter: brightness(0.5);
    }
  }

  &__video {
    border-radius: 21px;
    bottom: 0;
    height: 720px;
    left: 0;
    margin: auto;
    outline: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 1280px;
  }
}

@media screen and (max-width: 1536px) {
  .main {
    &__video {
      height: 600px;
      width: 800px;
    }
  }
}

@media screen and (aspect-ratio: 4/3) {
  .main {
    &__play {
      left: 82vw;

      height: 21.5vh;
      width: 16vw;
    }
  }
}

@media screen and (aspect-ratio: 5/4) {
  .main {
    &__play {
      left: 84vw;

      height: 21.5vh;
      width: 17.5vw;
    }
  }
}

@media screen and (aspect-ratio: 8/5) {
  .main {
    &__play {
      left: 76.5vw;

      height: 21.5vh;
      width: 13.5vw;
    }
  }
}

@media screen and (aspect-ratio: 16/9) {
  .main {
    &__play {
      left: 74vw;

      height: 21.5vh;
      width: 12vw;
    }
  }
}
