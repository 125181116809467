@import "src/styles/mixins.scss";

@keyframes background {
  from {
    background-position: -200px;
  }
  to {
    background-position: 0px;
  }
}

.nav-bar {
  @include row;

  align-items: center;
  justify-content: space-between;

  position: fixed;

  height: 13rem;
  padding: 0 5rem;
  width: 100%;

  z-index: 1;

  &__main {
    align-items: center;
    display: flex;
    justify-content: center;

    width: 9.5rem;

    img {
      width: 100%;

      &:hover {
        transform: scale(1.2);
        transition: transform 0.2s linear;
      }
    }
  }

  &__routes {
    @include row;

    align-items: center;
    gap: 1.5rem;
    justify-content: center;
  }

  &__route {
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;

    height: 100%;
    padding: 0.5rem 3.5715rem;

    color: #3cf3ff;
    font-size: 2.2865rem;
    line-height: 2.1435rem;
    text-decoration: none;

    &:hover {
      animation: background 100ms linear;
      background: url(~/src/assets/images/nav-background.webp) 0% 0% / contain no-repeat;
      background-position: center;
      color: black;
    }

    &--active {
      background: url(~/src/assets/images/nav-background.webp) 0% 0% / contain no-repeat;
      background-position: center;
      color: black;
    }
  }

  &__select {
    padding: 0;
    width: 7.5%;

    appearance: none;
    background: #331565;
    border: none;
    border-radius: 8px;
    outline: none;

    ul,
    li {
      background: #331565;
      border: none;
    }

    button {
      padding: 0.625rem;

      border: none;
    }

    span {
      color: #3cf3ff;
      font-size: 2.2865rem;
      line-height: 2.1435rem;
    }
  }
}
