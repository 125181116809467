@import "src/styles/mixins.scss";

.table {
  @include col;

  align-items: center;
  gap: 4.5rem;
  justify-content: flex-start;

  height: 100%;
  padding-top: 13.4rem;

  background: url(~/src/assets/images/table.webp) 0% 0% / cover no-repeat;
  background-position: center;

  &__buttons {
    @include row;

    gap: 1.5rem;
    justify-content: space-between;

    height: 6.071rem;
  }

  &__tournaments {
    @include row;

    flex: 1;
    gap: 0.857rem;
    justify-content: flex-end;
  }

  &__button {
    @include col;

    align-items: flex-start;
    justify-content: center;

    background: #240642;
    border: 0.071rem solid #5a22a0;
    border-radius: 0.571rem;
    color: #643f89;

    &:hover {
      background: #5808a9;
      border: 0.071rem solid #401a70;
      color: #ffffff;
    }

    &__calendar {
      width: 7.143rem;

      background:
        url(~/src/assets/images/calendar-default.png) 0% 0% / 30% no-repeat,
        #240642;
      background-position: center;

      &--active,
      &:hover {
        background:
          url(~/src/assets/images/calendar-active.png) 0% 0% / 30% no-repeat,
          #5808a9;
        background-position: center;
      }
    }

    &__next {
      width: 3.571rem;

      background:
        url(~/src/assets/images/next-default.png) 0% 0% / 20% no-repeat,
        #240642;
      background-position: center;

      &:hover {
        background:
          url(~/src/assets/images/next-active.png) 0% 0% / 20% no-repeat,
          #5808a9;
        background-position: center;
      }
    }

    &__prev {
      width: 3.571rem;

      background:
        url(~/src/assets/images/prev-default.png) 0% 0% / 20% no-repeat,
        #240642;
      background-position: center;

      &:hover {
        background:
          url(~/src/assets/images/prev-active.png) 0% 0% / 20% no-repeat,
          #5808a9;
        background-position: center;
      }
    }

    &__tournament {
      padding: 1.143rem 1.714rem;
      width: 14.571rem;

      &--selected {
        background: #5808a9;
        border: 0.071rem solid #401a70;
        color: #ffffff;
      }
    }
  }

  &__title {
    font-size: 2.286rem;
    text-align: left;

    &--date {
      font-size: 1.286rem;
    }
  }

  &__info {
    @include row;

    gap: 4.143rem;

    padding: 0 20.8rem;
    width: 100%;

    > div:first-child {
      flex: 0.285;
    }
    > div:last-child {
      flex: 0.715;
    }
  }

  &__racers {
    @include col;

    gap: 1.429rem;
  }

  &__calendar {
    @include row;

    gap: 5rem;
  }
}

.react-datepicker {
  background: none;
  border: none;

  &__header {
    margin-bottom: 2.143rem;

    background: none;
    border: none;
  }

  &__current-month {
    margin-bottom: 3.571rem;

    color: #98a3ad;
    font-size: 3.714rem;
    line-height: 3.714rem;
  }

  &__navigation {
    display: none;
  }

  &__month {
    margin: 0;
  }

  &__week {
    display: flex;
  }

  &__day-names {
    display: flex;
    justify-content: space-around;
  }

  &__day-name {
    color: #98a3ad;
    font-size: 2rem;
    line-height: 2rem;
  }

  &__day {
    align-items: center;
    display: flex;
    justify-content: center;

    height: 3.571rem;
    width: 3.571rem;

    color: #3d495a;
    font-size: 2rem;
    line-height: 2rem;

    &--selected,
    &--keyboard-selected {
      background: none;
    }

    &--highlighted {
      background: #097366;
      color: #ffffff;
    }

    &:hover {
      background: #5c1b7a;
      color: #ffffff;
    }
  }
}
