@import "src/styles/mixins.scss";

.racers {
  @include row;

  gap: 1.5%;

  height: 100%;
  padding: 15% 0% 0% 6%;

  background: url(~/src/assets/images/racers.png) 0% 0% / cover no-repeat;
  background-position: center;

  &__list {
    @include col;

    gap: 5%;

    height: 70%;
    width: 25%;

    overflow-x: hidden;
    overflow-y: scroll;

    &-item {
      color: #3d495a;
      font-size: 3.4285rem;

      &--selected {
        color: #3bf3ff;
      }

      &:hover {
        color: #3bf3ff;
      }
      &:active {
        color: #ffffff;
      }
    }
  }

  &__racer {
    @include col;

    flex: 1;
    gap: 25%;

    height: 100%;
  }

  &__avatar {
    bottom: 0;
    position: absolute;
    right: 0;

    width: 45%;
  }

  &__position {
    left: 25%;
    position: absolute;
    top: -10%;

    background: linear-gradient(180deg, rgba(129, 205, 237, 0.5) 0.05%, rgba(99, 195, 236, 0) 85%);
    background-clip: text;
    color: transparent;
    font-size: 45rem;
    line-height: 35rem;
  }

  &__nickname {
    @include col;

    color: #ffffff;
    text-transform: uppercase;
  }

  &__name {
    font-size: 8.1435rem;
    line-height: 7.7145rem;
  }

  &__surname {
    font-size: 19.1435rem;
    line-height: 14.4435rem;

    &--long {
      font-size: 8.1435rem;
      line-height: 7.7145rem;
    }
  }

  &__data {
    @include col;

    width: 35%;

    div {
      @include row;

      justify-content: space-between;

      padding-bottom: 2rem;
      padding-top: 2rem;

      border-bottom: 1px solid #8dd1eead;
      color: #cbddf8;

      span {
        font-size: 3.3465rem;
        line-height: 3.1665rem;
      }
    }
  }
}
