@import "src/styles/mixins.scss";

.regulations {
  @include col;

  gap: 2.625rem;
  justify-content: center;

  height: 100%;
  padding-left: 7.825rem;
  padding-top: 6rem;

  background: url(~/src/assets/images/regulations.webp) 0% 0% / cover no-repeat;
  background-position: center;

  &__regulation {
    @include row;

    gap: 3.5rem;
  }

  &__img {
    width: 6rem;
  }

  &__label {
    @include col;

    justify-content: flex-end;
  }

  &__link {
    color: #ffffff;
    font-size: 2.285rem;
    text-decoration: none;

    &:hover {
      color: #3cf3ff;
    }
  }
}
