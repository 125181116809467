@import "src/styles/mixins.scss";

.form {
  @include col;

  gap: 5rem;

  padding-top: 5rem;

  &__header,
  &__body {
    @include col;
  }

  &__body {
    gap: 4rem;

    > div {
      @include row;
      gap: 8rem;
    }

    > div:last-child {
      @include row;

      justify-content: flex-end;
    }
  }

  &__title {
    color: #586a84;
    font-size: 2rem;
    line-height: 2rem;

    &--secondary {
      color: #ffffff;
      font-size: 5rem;
      line-height: 5.9rem;
    }
  }

  &__field {
    .MuiInputBase-root::after {
      border-bottom: 0.1vw solid #3bf3ff;
    }

    label {
      span {
        color: #3bf3ff;
        font-size: 2rem;
        line-height: 2rem;
      }
    }

    input {
      color: #ffffff;
      font-size: 2rem;
      line-height: 2rem;
    }
  }

  &__send {
    height: 5.857rem;
    width: 32.857rem;

    background: #3bf3ff;
    border: none;
    outline: none;
    padding: 0;
    clip-path: polygon(0 0, 97% 0, 100% 15%, 100% 100%, 3% 100%, 0 85%);
  }

  &__send-label {
    font-size: 2.857rem;
  }

  &__notification {
    min-width: fit-content;
    width: fit-content;

    svg {
      margin-inline-end: 0 !important;
    }
  }
}
