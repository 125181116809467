@import "src/styles/mixins.scss";

.racer {
  @include row;

  background: #002527;
  border-radius: 0.85rem;
  color: #cbddf8;

  padding: 1.15rem 1.85rem;

  span {
    font-size: 2.3rem;
    line-height: 2.3rem;
  }

  gap: 1.7rem;
  justify-content: space-between;

  &--first {
    background: #016268;
  }

  &--second {
    background: #02474b;
  }

  &--third {
    background: #002e31;
  }

  span:nth-of-type(2) {
    width: 100%;
  }
}
