/* Aviano fonts */

@font-face {
  font-family: "Aviano";
  src: url("./fonts/Aviano-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* BungeeSpice fonts */

@font-face {
  font-family: "BungeeSpice";
  src: url("./fonts/BungeeSpice-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* ClimateCrisis fonts */

@font-face {
  font-family: "ClimateCrisis";
  src: url("./fonts/ClimateCrisis-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* SeymourOne fonts */

@font-face {
  font-family: "SeymourOne";
  src: url("./fonts/SeymourOne-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Ultra fonts */

@font-face {
  font-family: "Ultra";
  src: url("./fonts/Ultra-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Inter fonts */

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter-Black.ttf") format("truetype");
  font-weight: 900;
}

/* Lato fonts */

@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Thin.ttf") format("truetype");
  font-weight: 100;
}

@font-face {
  font-family: "Inter";
  src: url("./fonts/Lato-ExtraLight.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
}

/* Digital fonts */

@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-ExtraBold.ttf") format("truetype");
  font-weight: 800;
}


@font-face {
  font-family: "Lato";
  src: url("./fonts/Lato-Black.ttf") format("truetype");
  font-weight: 900;
}

@font-face {
  font-family: "Digital";
  src: url("./fonts/Digital-Regular.ttf") format("truetype");
  font-weight: 400;
}

/* Giorgio Sans LCG fonst */

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/Giorgio\ Sans\ LCG\ Regular.otf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Giorgio Sans LCG";
  src: url("./fonts/Giorgio\ Sans\ LCG\ Bold.otf") format("truetype");
  font-weight: 700;
}

/* Arm Hmk's Bebas Neue */

@font-face {
  font-family: "Arm Hmk's Bebas Neue";
  src: url("./fonts/Arm\ Hmk\'s\ Bebas\ Neue.ttf") format("truetype");
}
