@import "src/styles/mixins.scss";

.games {
  &__games {
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: min-content;
    grid-column-gap: 5rem;
    grid-row-gap: 2rem;
    grid-template-columns: repeat(2, auto);

    height: 45rem;
    padding: 0 1.071rem;

    overflow-y: scroll;
  }

  &__button {
    bottom: 0;
    position: absolute;
    top: 0;

    background: none;
    border: none;
    outline: none;

    &:active {
      filter: brightness(0.5);
    }
  }
}
